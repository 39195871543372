<template>
  <div class="container text-center bg-white">
    <div class="container_main py-0">
      <div class="px-4">
        <h1 class="mb-4">
          Press start and then<br>
          <strong>take a deep breath</strong><br>
          into your microphone<br>
        </h1>
        <p>1. Press <strong>start</strong></p>
        <p>2. Hold the microphone of your phone approx <strong>2 inches</strong> from your mouth</p>
        <p>3. Take a <strong>deep breath in</strong></p>
        <p>Follow the video below</p>
      </div>
      <div class="px-5">
        <img src="images/video-thumb.svg" class="w-100 mt-3 mb-3">
      </div>
    </div>
    <div class="container_footer p-3">
      <b-button variant="primary" @click="$router.push('/main')" class="d-block w-100 p-2">Start</b-button>
    </div>
  </div>
</template>

<script>
export default {
  middleware: 'check-selection',
}
</script>

<style scoped lang="scss">
  .container_footer {
    position: fixed;
    bottom: 0;
    width: 100%;

    background: white;
  }

  img {
    object-fit: cover;
    height: 10rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  p {
    margin-bottom: 0.5rem;
  }
</style>